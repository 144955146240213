import { datadogRum } from '@datadog/browser-rum';
import { isServer } from '@komo-tech/core/utils/browser';

import { ConsentCookieService } from '@/common/utils/ConsentCookieService';
import { isProduction } from '@/common/utils/NextFunctions';

export const ignoredErrors = [
  'TypeError: NetworkError when attempting to fetch resource.',
  'TypeError: cancelled',
  'TypeError: Cancelled',
  'message: cancelled',
  'cancelled',
  'Failed to fetch',
  'TypeError: Failed to fetch',
  'Object Not Found Matching Id:',
  'getPortalId', //Hubspot
  'TypeError: n.report is not a function', //Hubspot
  'ResizeObserver loop limit exceeded'
];

// Regex patterns to identify known bot instances, we shouldn't record these sessions
// https://docs.datadoghq.com/real_user_monitoring/guide/identify-bots-in-the-ui/
const botPattern =
  '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';
const regex = new RegExp(botPattern, 'i');

export const shouldIgnoreError = (error) => {
  try {
    return ignoredErrors.some((x) => error?.message.includes(x) ?? false);
  } catch (error) {
    console.error(error);
    return false;
  }
};

const shouldInit = () => {
  if (ConsentCookieService.hasSavedCookieSettings()) {
    if (ConsentCookieService.hasConsented() === false) {
      return false;
    }
  }

  return true;
};

export const init = () => {
  if (
    !isServer() &&
    isProduction &&
    window.location.hostname !== 'localhost' &&
    shouldInit()
  ) {
    try {
      // Check if the userAgent is a known bot, and exclude from session recording if a match
      const conditionalSampleRate = regex.test(navigator.userAgent) ? 0 : 100;

      datadogRum.init({
        applicationId: '42286e5d-9e76-415b-9831-55d94f34e4c1',
        clientToken: 'pub6a44278073aa9e84796c9bca3bef10cd',
        site: 'datadoghq.com',
        service: process.env.NEXT_PUBLIC_KOMO_SERVICE || 'komo-platform',
        env: process.env.NEXT_PUBLIC_KOMO_ENVIRONMENT || 'development',
        version: process.env.NEXT_PUBLIC_KOMO_VERSION || '000000000',
        allowedTracingUrls: window ? [window.location.origin] : [],
        sessionSampleRate: conditionalSampleRate,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        beforeSend: (event) => {
          if (event.type === 'error' && shouldIgnoreError(event.error)) {
            return false;
          }
        }
      });

      datadogRum.startSessionReplayRecording();
    } catch (err) {
      console.error(err);
    }
  }
};
